<template>
  <div>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">退货基本信息</span>
      </el-col>
    </el-row>
    <el-form ref="ruleFormStyle" :disabled="fordisabled" :model="ruleForm" label-width="100px">
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item label="采购退货单" prop="baseStyleId">
            <el-input v-model="ruleForm.returnCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <el-input v-model="ruleForm.vendorName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-input v-model="ruleForm.returnStatusI18" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="预期退货数量">
            <el-input v-model="ruleForm.expectReturnPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预期退货金额">
            <el-input v-model="ruleForm.expectReturnPrice" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="退货原因">
            <el-input v-model="ruleForm.returnReasonI18" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <!--  <el-col :span="8">
          <el-form-item label="运费承担方">
            <el-select v-model="ruleForm.returnReasonI18" disabled>
              <el-option label="供应商" value="1" />
              <el-option label="我司" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="退货立方数">
            <el-input v-model="ruleForm.lfs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="退货装货时间">
            <el-input v-model="ruleForm.returnReasonI18" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="退货数量">
            <el-input v-model="ruleForm.actualReturnPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="退货金额">
            <el-input v-model="ruleForm.actualReturnPrice" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人">
            <el-input v-model="ruleForm.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="创建日期">
            <el-input v-model="ruleForm.createTime" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="备注">
            <el-input v-model="ruleForm.remarks" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <!--     <el-row>
      <div>退货明细</div>
      <el-divider />
    </el-row> -->
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">退货明细</span>
      </el-col>
    </el-row>
    <ReturnTable :flag="false" :table-datas="ruleForm.detailVOList" />
  </div>
</template>

<script>
import ReturnTable from '../components/index'
import { getReturnInfoById } from '@/api/scm-api'
export default {
  components: { ReturnTable },
  data() {
    return {
      ruleForm: {},
      fordisabled: false
    }
  },
  created() {

  },
  mounted() {
    this._getReturnInfoById(this.$route.query.id)
  },
  methods: {
    async _getReturnInfoById(id) {
      const { datas } = await getReturnInfoById(id)
      this.ruleForm = datas
    }
  }
}
</script>

<style scoped lang="scss">
</style>
