<template>
  <div>
    <el-table
      ref="tableDatas"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="450px"
      :summary-method="getSummaries"
      show-summary
      @selection-change="SelectionChange"
    >
      <el-table-column v-if="flag" type="selection" width="60" align="center" />
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <!-- <el-table-column prop="fnsku" label="FNSKU/UPC" min-width="105" /> -->
      <el-table-column label="规格型号" width="150">
        <template slot-scope="scope">
          {{ scope.row.style }}<br>
          {{ scope.row.color }}<br>{{ scope.row.size }}
        </template>
      </el-table-column>
      <!-- <el-table-column width="110px" label="平台/站点">
        <template slot-scope="scope">{{ scope.row.platformCode }}/{{ scope.row.siteCode }}</template>
      </el-table-column> -->
      <el-table-column prop="purchaseOrderCode" label="采购订单" min-width="120" />
      <el-table-column prop="collectCode" label="收货单号" min-width="120" />
      <el-table-column prop="vendorName" width="110px" label="供应商" />
      <el-table-column prop="currencyType" label="币种" width="160" align="center" sortable>
        <template slot-scope="scope">
          <!-- <p v-if="scope.row.currencyType == '1'">美元</p>
          <p v-if="scope.row.currencyType == '2'">人民币</p> -->
          {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
        </template>
      </el-table-column>
      <template v-if="flag">
        <el-table-column prop="purchasePairs" width="110px" label="采购总数量" />
        <el-table-column prop="collectPairs" label="入库数量" />
        <el-table-column width="110px" label="本次退货数量" prop="returnPairs">
          <template slot-scope="scope">
            <el-input
              v-model.number="scope.row.returnPairs"
              :disabled="!flag"
              placeholder="enter键确认"
              @change="inputClick()"
              @input="input(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column width="110px" label="可退货数量" prop="surplusReturnPairs" />
        <el-table-column prop="purchasePrice" width="110px" label="采购单价" />
        <el-table-column prop="purchaseTotalPrice" label="采购总金额" width="120" />
        <el-table-column width="110px" prop="returnTotalPrice" label="本次退货金额" />
        <el-table-column prop="collectWarehouseName" label="收货仓库" />
        <el-table-column prop="collectTime" label="收货时间" width="100" />
      </template>
      <template v-else>
        <el-table-column prop="purchasePrice" width="110px" label="采购单价" />
        <el-table-column prop="actualReturnPairs" width="110px" label="实际退货数量" />
        <el-table-column prop="actualReturnTotalPrice" width="120px" label="实际退货金额" />
        <el-table-column prop="expectReturnPairs" width="120px" label="预计退货数量" />
        <el-table-column prop="expectReturnTotalPrice" width="120px" label="预计退货金额" />
      </template>
    </el-table>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  mixins: [commodityInfoDict],

  props: {
    tableDatas: {
      type: Array,
      default() {
        return []
      }
    },
    tableDatasLoading: {
      type: Boolean,
      default: false
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      multipleSelection: []
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    input(row) {
      let outPairsBoo = true
      if (row.returnPairs < 0 || row.returnPairs > row.surplusReturnPairs) outPairsBoo = false
      this.$refs.tableDatas.toggleRowSelection(row, outPairsBoo)
      row.returnTotalPrice = (row.purchasePrice * row.returnPairs).toFixed(2)
    },
    inputDetailClick() { },
    inputClick() {
      if (this.multipleSelection.find(item => item.returnPairs < 0 || item.returnPairs > item.surplusReturnPairs)) {
        this.$message.warning('本次退货数量填写有误')
        return
      }
      this.$emit('getMultipleSelection', this.multipleSelection)
    },
    SelectionChange(val) {
      this.multipleSelection = val
      this.$emit('getMultipleSelection', val)
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 7) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>

<style scoped lang="scss">
</style>
